@import "~@/styles/variables/variables.scss";




































































































































































































































































































































































































.page-main-left {
	padding-left: 0;
	padding-right: 0;
	.role-item {
		height: 34px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px 0 22px;
		font-size: 14px;
		box-sizing: border-box;
		&.active,
		&:hover {
			background: #ebedfa;
			.role-name {
				color: $base-color-primary;
			}
		}
		.role-name {
			color: #666666;
			margin-right: 10px;
		}
		.split-block {
			flex: 1;
		}
		.iconfont {
			font-size: 12px;
			&.icon-yellow {
				color: #ffd633;
			}
			&.icon-blue {
				padding-left: 17px;
				color: $base-color-primary;
			}
		}
	}
}
.page-main-right {
	padding-left: 0;
	padding-right: 0;
	display: flex;
	flex-direction: column;
	.pale-title {
		margin: 32px 0 18px 27px;
		font-size: 14px;
		color: #ababab;
		line-height: 21px;
	}
	.basic-info {
		font-size: 14px;
		color: #666666;
		line-height: 21px;
		.role-name {
			padding-left: 10px;
			color: #333333;
		}
	}
	.model-form {
		margin: 0 0 3px 27px;
		display: flex;
		align-items: center;
	}
}
::v-deep .el-tree {
	flex: 1;
	padding-left: 24px;
	overflow: scroll;
	.el-tree-node__content {
		// padding-left: 24px;
	}

	.el-checkbox {
		margin-left: 10px;
		margin-right: 16px;
	}
}
::v-deep .el-form {
	.el-form-item {
		margin-bottom: 8px;
	}
	&.role {
		.el-form-item__error {
			padding-left: 92px;
		}
	}
	&.templateRole {
		.el-form-item__error {
			padding-left: 122px;
		}
	}

	.el-input {
		width: 300px;
		height: 40px;
		background: #ffffff;
		border-radius: 8px;
		border: 1px solid #cccccc;
		overflow: hidden;
		.el-input-group__prepend {
			position: relative;
			padding: 0 14px;
			font-size: 14px;
			color: #666666;
			background: transparent;
			border: none;
			box-sizing: border-box;
			&::after {
				position: absolute;
				margin-left: 14px;
				content: '';
				height: 16px;
				border-right: 1px solid #9ea8a3;
				display: inline-block;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.el-input__inner {
			border: none;
			height: 38px;
			line-height: 38px;
		}
	}
}
