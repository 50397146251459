@import "~@/styles/variables/variables.scss";








































































::v-deep {
	.grant {
		font-size: 14px;
		color: #4d6bff;
		line-height: 21px;
		cursor: pointer;
	}
}
